<template>
  <v-container>
    <v-card flat>
      <v-card-text>
        <v-data-table
          v-if="areas"
          :headers="headers"
          :items="areas"
          class="elevation-2"
        >
          <template v-slot:top>
            <v-toolbar dense flat class="teal" dark>
              <v-toolbar-title>Áreas</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{}">
                  <v-btn class="mt-2 mb-2" icon x-large to="/areas/adicionar">
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
                Adicionar novo Área
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon @click="edit(item)" class="mr-2 colorLink"
              >mdi-square-edit-outline</v-icon
            >
            <v-icon @click="remove(item)" class="mr-2" color="error"
              >mdi-delete</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" max-width="500px" v-if="editDialog">
      <editArea
        :status="area"
        v-on:update="loadAreas()"
        v-on:close-dialog="editDialog = !editDialog"
      />
    </v-dialog>
    <v-dialog v-model="removeDialog" max-width="500px" v-if="removeDialog">
      <removeArea
        :status="area"
        v-on:update="loadAreas()"
        v-on:close-dialog="removeDialog = !removeDialog"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import editArea from "./edit";
import removeArea from "./remove";
export default {
  components: {
    editArea,
    removeArea
  },
  data() {
    return {
      areas: [],
      area: {},
      editDialog: false,
      removeDialog: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Área", value: "name" },
        { text: "Ações", value: "actions" }
      ]
    };
  },
  created() {
    this.loadAreas();
  },
  methods: {
    async loadAreas() {
      const response = await this.$http.get("/area");
      this.areas = response.data;
    },
    async edit(area) {
      this.editDialog = true;
      this.area = area;
    },
    async remove(area) {
      this.removeDialog = true;
      this.area = area;
    }
  }
};
</script>

<style></style>
